import { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Select, Table, notification } from 'antd';
import dayjs from 'dayjs';
import { useGlobal } from '../../../System/Global/GlobalProvider';
import { useForm } from 'antd/es/form/Form';
import { get, save, sendEmail } from '../../../System/Api/API';

export const Emails = () => {

    const { campaign, UserData } = useGlobal();
    const [ emails, setEmails ] = useState([])
    const [ showModal, setShowModal ] = useState(false)
    const [ form ] = useForm();
    
    useEffect( () => {

        loadEmails()
        console.log(campaign)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadEmails = async () => {

        const _emails = await get('emails', { em_cm_id: campaign.cm_id}, {em_created: 'DESC'}, ["emails.*, users.*"], [{table: "users", condition: "users.us_id = em_us_id"}])

        setEmails(_emails)

    }

    let cols = [
        {
            title: 'Email sent',
            render: item => dayjs(item.em_created).format('DD/MM/YYYY HH:mm'),
            key: 'date',
        },
        {
            title: 'Month',
            render: item => <div>{item.em_month} ({dayjs('2024-04-01').add(item.em_month , 'months').format('MMM YY')})</div>,
            key: 'month',
        },
        {
            title: 'Author',
            key: 'author',
            render: item => `${item.us_first_name} ${item.us_last_name}`
        },
        
    ]

    const newEmail = () => {

        return (
            <div>
                <Card>
                    <Form onFinish={submitEmail} form={form} initialValues={{email: campaign.cm_email}}>
                        <Form.Item label="" name="email">
                            <Input type="email" placeholder="Email address" />
                        </Form.Item>
                        <Form.Item label="" name="month">
                            <Select>
                            {Array.from({ length: 12 }, (_, index) => (
                                <Select.Option key={index} value={index + 1}>
                                    {`Month ${index + 1}`}
                                </Select.Option>
                            ))}
                                
                            </Select>
                        </Form.Item>
                    
                    </Form>
                </Card>
                <div dangerouslySetInnerHTML={{__html: emailTemplate(campaign.cm_website.toUpperCase() === "Y" ? true : false)}}/>
            </div>
        )
    }

    const submitEmail = async (values) => {

        console.log("send email")
        const data = {
            em_to:          values.email,
            em_cm_id:       campaign.cm_id,
            em_month:       values.month,
            em_us_id:       UserData.us_id,
        }

        // get the files

        let files = await get('files', {fi_cm_id: campaign.cm_id, fi_month: values.month});
        files = files.map(file => unescape(file.fi_location));

        console.log(data, files)


        await sendEmail(data.em_to, campaign.cm_website === "Y" ? true : false, files)
        
        await save('emails', data)

        const _emails = await get('emails', { em_cm_id: campaign.cm_id}, {em_created: 'DESC'}, ["emails.*, users.*"], [{table: "users", condition: "users.us_id = em_us_id"}])

        setEmails(_emails)

        notification.success({message: "Success", description: "Your email has been sent"})

        setShowModal(false)

    }

    const emailStyle = () => {

        return (
            `<style>
            /* -------------------------------------
                INLINED WITH htmlemail.io/inline
            ------------------------------------- */
            /* -------------------------------------
                RESPONSIVE AND MOBILE FRIENDLY STYLES
            ------------------------------------- */
            @media only screen and (max-width: 620px) {
              table[class=body] h1 {
                font-size: 28px !important;
                margin-bottom: 10px !important;
              }
              table[class=body] p,
                    table[class=body] ul,
                    table[class=body] ol,
                    table[class=body] td,
                    table[class=body] span,
                    table[class=body] a {
                   
                font-size: 16px !important;
              }
              table[class=body] .logo {
                padding: 00px !important;
                background: transparent;
              }
              table[class=body] .wrapper,
                    table[class=body] .article {
                padding: 0px !important;
              }
              table[class=body] .content {
                padding: 0 !important;
              }
              table[class=body] .container {
                padding: 0 !important;
                width: 100% !important;
              }
              table[class=body] .main {
                border-left-width: 0 !important;
                border-radius: 0 !important;
                border-right-width: 0 !important;
              }
              table[class=body] .btn table {
                width: 100% !important;
              }
              table[class=body] .btn a {
                width: 100% !important;
              }
              table[class=body] .img-responsive {
                height: auto !important;
                max-width: 100% !important;
                width: auto !important;
              }
            }
            /* -------------------------------------
                PRESERVE THESE STYLES IN THE HEAD
            ------------------------------------- */
            @media all {
              .ExternalClass {
                width: 100%;
              }
              .ExternalClass,
                    .ExternalClass p,
                    .ExternalClass span,
                    .ExternalClass font,
                    .ExternalClass td,
                    .ExternalClass div {
                line-height: 100%;
              }
              .apple-link a {
                color: inherit !important;
                font-family: inherit !important;
                font-size: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
                text-decoration: none !important;
              }
              .btn-primary table td:hover {
                background-color: #34495e !important;
              }
              .btn-primary a:hover {
                background-color: #34495e !important;
                border-color: #34495e !important;
              }
              p {
              line-height: 1.5;
              }
            }
            </style>`
        )
    }
    const emailTemplate = (tlWebsite) => {
    
    
        return (`<!doctype html>
        <html>
          <head>
            <meta name="viewport" content="width=device-width">
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <title>Your Thomson Local Monthly SEO Report</title>
            ${emailStyle()}
          </head>
          <body class="" style="width: 100%; background-color: #f6f6f6; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
            <table border="0" cellpadding="0" cellspacing="0" class="body" style="margin: 0 auto; border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background-color: #f6f6f6;">
              <tr>
                <td class="container" style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; Margin: 0 auto; max-width: 780px; padding: 10px; width: 100%;">
                  <div class="content" style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 780px; padding: 10px;">
        
                    <!-- START CENTERED WHITE CONTAINER -->
                    <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">Your Thomson Local Monthly SEO Report</span>
                    <table class="" style="max-width: 780px; padding: 0 0px; border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background: #ffffff; border-radius: 3px;">
                    ${emailContent(tlWebsite)}
                    </table>
        
                    <!-- START FOOTER -->
                    <div class="footer" style="clear: both; Margin-top: 10px; text-align: center; width: 100%;">
                      <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;">
                        <tr>
                          <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; font-size: 12px; color: #999999; text-align: center;">
                            <span class="apple-link" style="color: #999999; font-size: 12px; text-align: center;"></span>
                           
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!-- END FOOTER -->
                  <!-- END CENTERED WHITE CONTAINER -->
                  </div>
                </td>
              </tr>
            </table>
          </body>
        </html>`)
    }
    const emailContent = (tlWebsite) => {
    
        return (`
        <!-- START MAIN CONTENT AREA -->
                  <tr>
                    <td class="logo" style="background-color: #356FB5; font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 15px 30px;">
                        <img width="200px" src="/images/tllogo.jpg"/>
                    </td>
                  </tr>
                  <tr>
                 
                    <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;">
                      <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;">
                        <tr>
                          <td style="font-family: sans-serif; font-size: 14px; vertical-align: top; padding: 15px 30px;">
                            <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">
                            Hi,
                            </p>
                            <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">
                            As part of your SEO service with thomsonlocal we continue to deliver monthly reports detailing the work that has been undertaken to improve the SEO performance of your website.
                            </p>
                            <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">
                            These reports range from Content Optimisation through to Technical SEO, with the main goal being to improve your online visibility and reach.
                            </p>
    
                            ${!tlWebsite ? `<p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">
                            Please forward the attached reporting to your website provider.
                            </p>` 
                            : 
                            `<p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">
                                As thomsonlocal provide your website service we will action the changes detailed in the reports on your behalf.
                            </p>`}
    
                            <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">
                                If you have any questions, please do not hesitate in calling our Customer and Product Support Team on 03330 145 045 or email info@thomsonlocal.com
                            </p>
    
                            <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; Margin-bottom: 15px;">
                                Many thanks<br/><br/>Thomson Local Team
                            </p>
                            </td>
                        </tr>
                        
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td class="logo" style="background-color: #356FB5; font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 15px 30px;">
                        <img width="150px" src="/images/tllogo.jpg"/>
                        <br/><br/>
                        <p style="color: #ffffff;">
                        Thomson Local is a trading name of Thomson Directories Ltd, registered in England and Wales. Registered number: 08597012.
                        </p>
                    </p>
                    </td>
                  </tr>
    
                <!-- END MAIN CONTENT AREA -->
                `)
    }

    
    return (
        
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Emails</h1>
                </div>
                <div>
                    <Button onClick={() => setShowModal(true) } size="large" type="primary">New email</Button>
                </div>
            </div>
            
            <Table 
                dataSource={emails} 
                rowKey="em_id" 
                columns={cols} 
                pagination={false}
            />

            <Modal open={showModal} okText="Send" title="New Email" onCancel={() => setShowModal(false)} width={700} onOk={() => form.submit()}>

                { newEmail() }
            </Modal>
            
        </div>
    )
}

export default Emails;